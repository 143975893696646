import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IoMdArrowRoundForward } from 'react-icons/io';
import Header from '../components/Header';

import {
  SectionComponent,
  ParagraphComponent,
  HeadlineComponent
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';


const Container = styled.div`
  border: 1px solid #d1d1d1;
  margin: 1.5rem;
  border-radius: 5px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

    svg {
      color: ${({ theme }) => theme.colors.green};
    }
  }

  @media screen and (max-width: 768px) {
    height: 110px; 
  }
`;

export const Headline = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
  color: ${({ theme, color }) => color || theme.colors.primary};

  @media screen and (max-width: 768px) {
  font-size: 1rem;
    } 
`;

export const DateComponent = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #707070;
  display: inline-block;
  text-align: left;
  font-style: italic;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
  } 
`;
export const RightContainer = styled.div`
  min-width: 130px;
  height: 100%;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-left: 2px solid ${({ theme }) => theme.colors.green};
`;

export const LeftContainer = styled.div`
  padding: 15px;
`;

export const RightText = styled.p`
  color: white;
  margin-right: 6px;
`;

export const IconComponent = styled.div`
  font-size: 2rem;
  display: flex;
  color: white;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};

  svg {
    transition: color 0.2s ease;
  }
`;

function BlogPage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "blog-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blogData: allContentfulBlog(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            slug
            date(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Latest Poker News"
        link="https://teampoker.com/gallery"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header
        image={data.background.childImageSharp.fluid}
        text="LATEST POKER NEWS"
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Team Poker® Official Blog</HeadlineComponent>
        <ParagraphComponent>
          Find the latest poker tournament news in our official blog page!
        </ParagraphComponent>
        <div>
          {data.blogData.edges.map(edge => {
            return (
              <Link
                key={edge.node.date}
                to={`/blog/${edge.node.slug.replace(/\s/g, '-')}`}
              >
                <Container>
                  <LeftContainer>
                    <Headline>{edge.node.title}</Headline>
                    <DateComponent>{edge.node.date}</DateComponent>
                  </LeftContainer>
                  <RightContainer>
                    <RightText>Read</RightText>
                    <IconComponent>
                      <IoMdArrowRoundForward />
                    </IconComponent>
                  </RightContainer>
                </Container>
              </Link>
            );
          })}
        </div>
      </SectionComponent>
    </Layout>
  );
}

export default BlogPage;
